import "./Login.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Login() {
    const [blockedTime, setBlockedTime] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    function isErro() {
        const erro = document.querySelector("#erro");
        if (erro) {
            erro.innerHTML = "";
            document.querySelector("#email").style.borderColor = "white";
            document.querySelector("#password").style.borderColor = "white";
        }
    }

    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }

    function Relogio(props) {
        const dataExp = new Date(localStorage.getItem("tempo"));
        const dataAtual = new Date();

        if (props.blockedTime == 0) {
            if (dataExp > dataAtual) {
                let conta = Math.ceil((dataExp - dataAtual) / 1000);
                setBlockedTime(conta);
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }

        if (props.blockedTime > 0) {
            //setBlockedTime(localStorage.getItem("tempo"))
            return (
                <div id="relogio" classList="texto-branco">
                    Tempo de espera: {formatTime(props.blockedTime)}
                </div>
            );
        }
    }

    function focusEmail() {
        let email = document.querySelector("#email").value;
        let emailCampo = document.querySelector("#email");
        if (email !== "") {
            emailCampo.classList.add("foco-pagamentos");
        } else {
            emailCampo.classList.remove("foco-pagamentos");
        }
    }

    function focusSenha() {
        let senha = document.querySelector("#password").value;
        let senhaCampo = document.querySelector("#password");
        if (senha !== "") {
            senhaCampo.classList.add("foco-pagamentos");
        } else {
            senhaCampo.classList.remove("foco-pagamentos");
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    useEffect(() => {
        let interval = null;
        if (blockedTime > 0) {
            interval = setInterval(() => {
                setBlockedTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [blockedTime]);

    async function sign(event) {
        try {
            const email = document.querySelector("#email").value;
            const password = document.querySelector("#password").value;
            const response = await axios.post(
                "/api/sign",
                {
                    email,
                    password,
                },
                {
                    withCredentials: true,
                }
            );

            if (response.data.status === 200) {
                window.location.href = "/";
            } else if (
                response.data.status === 400 ||
                response.data.msg == undefined
            ) {
                console.log("entrou no inválido");
                const erro = document.querySelector("#erro");
                while (erro.firstChild) {
                    erro.removeChild(erro.firstChild);
                }
                setTimeout(() => {
                    document.querySelector("#email").style.borderColor = "red";
                    document.querySelector("#password").style.borderColor =
                        "red";
                    erro.classList.add("texto-vermelho");
                    let divText = document.createTextNode(
                        "Usuário e/ou senha inválidos!"
                    ); // Cria o elemento de texto
                    erro.appendChild(divText);
                }, 50); //Pequeno delay de criação apenas para deixar mais intuitivo
            } else {
                alert(response.data.msg);
                setBlockedTime(response.data.time);
                localStorage.setItem("tempo", response.data.date);
                setIsDisabled(true);
            }
        } catch (error) {
            // Altere a estrutura do seu tratamento de erro para lidar com os diferentes códigos de status HTTP
            alert(error);
            if (error.response == "O usuário já está sendo utilizado") {
                alert("O usuário já está sendo utilizado");
            }
            if (error.response) {
                if (error.response.status === 400) {
                    alert("Usuário e senha inválidos!");
                    window.location.href = "/login";
                } else {
                    console.error(
                        "Erro na resposta do servidor:",
                        error.response
                    );
                    alert(
                        `Erro na resposta do servidor: ${error.response.status} ${error.response.statusText}`
                    );
                    window.location.href = "/bad/request";
                }
            } else if (error.request) {
                console.error("Erro ao realizar a solicitação:", error.request);
                alert("Erro ao realizar a solicitação.");
                window.location.href = "/bad/request";
            } else {
                console.error(
                    "Erro ao configurar a solicitação:",
                    error.message
                );
                alert(`Erro ao configurar a solicitação: ${error.message}`);
                window.location.href = "/bad/request";
            }
        }
    }

    return (
        <div className="login-container-pagamentos">
            <div className="">
                <div className="card-body">
                    <div className="row">
                        <div className="form-container-1-pagamentos">
                            <div className="icon-heading">
                                <img
                                    src="/dist/img/marca_almeira_e_nogueira_fundo_colorido.png"
                                    alt="Logo Pagamentos"
                                    className="icon-img"
                                />
                            </div>
                            <div className="text-portal">
                                <p>AN Documentos</p>
                            </div>
                            <div className="description-portal">
                                <p>
                                    Gerencie seus processos mantendo e trocando  documentos de forma fácil e
                                    segura com o AN Documentos.
                                </p>
                            </div>
                            <div className='wrapper-social'>
                                <a href='https://www.linkedin.com/company/almeidaenogueira/' className='href-social'>
                                    <div className='icon linkedin'>
                                        <div className='tooltip'>Linkedin</div>
                                        <div><i className='fab fa-linkedin'></i></div>
                                    </div>
                                </a>
                                <a href='https://www.instagram.com/almeidaenogueira/' className='href-social'>
                                    <div className='icon instagram'>
                                        <div className='tooltip'>Instagram</div>
                                        <div><i className='fab fa-instagram'></i></div>
                                    </div>
                                </a>
                                <a href='https://www.youtube.com/@anadvogados8921' className='href-social'>
                                    <div className='icon youtube'>
                                        <div className='tooltip'>Youtube</div>
                                        <div><i className='fab fa-youtube'></i></div>
                                    </div>
                                </a>
                                <a href='https://almeidaenogueira.com.br/' className='href-social'>
                                    <div className='icon site'>
                                        <div className='tooltip'>Site</div>
                                        <div><i className='fa fa-globe-americas'></i></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="form-container-2-pagamentos">
                            <h2 className="center-element">Login</h2>
                            <Relogio
                                blockedTime={blockedTime}
                                classList="text-white"
                            />
                            
                            <div id="erro">
                                {isErro()}
                            </div>
                            <div className="row input-group-pagamentos">
                                <input
                                    type="email"
                                    id="email"
                                    className="campo-pagamentos"
                                    onChange={focusEmail}
                                    disabled={isDisabled}
                                />
                                <label id="tag-email">Email</label>
                            </div>
                            <div className="row input-group-pagamentos-senha">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    className="campo-pagamentos"
                                    onChange={focusSenha}
                                    disabled={isDisabled}
                                />
                                <label id="tag-senha">Senha</label>
                                <div className="show-hide-login">
                                    <IconButton
                                        onClick={togglePasswordVisibility}
                                        aria-label={
                                            showPassword
                                                ? "Ocultar senha"
                                                : "Mostrar senha"
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                            <button
                                onClick={() => {
                                    sign();
                                }}
                                className="btn-entrar-pagamentos"
                                disabled={isDisabled}
                            >
                                Entrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
